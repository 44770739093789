import React, {
  createContext,
  useContext,
  useState,
} from "react";

interface AdminOrdersContextType {
  isSendingInvoice: boolean;
  setIsSendingInvoice: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminOrdersContext = createContext<
  Partial<AdminOrdersContextType>
>({});

export const useAdminOrdersContext = () => {
  const context = useContext(AdminOrdersContext);
  if (context === undefined) {
    throw new Error(
      "useAdminOrdersContext must be used within a AdminOrdersProvider"
    );
  }
  return context as AdminOrdersContextType;
};

export const AdminOrdersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSendingInvoice, setIsSendingInvoice] = useState(false);

  const value: AdminOrdersContextType = {
    setIsSendingInvoice,
    isSendingInvoice,
  };

  return (
    <AdminOrdersContext.Provider value={value}>
      {children}
    </AdminOrdersContext.Provider>
  );
};
