import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";

interface IConfirmDeleteOrder {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}

export const ConfirmDeleteOrderModal: React.FC<IConfirmDeleteOrder> = ({
  isOpen,
  onCancel,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-delete-order-title"
      aria-describedby="alert-dialog-delete-order-description"
    >
      <DialogTitle id="alert-dialog-delete-order-title">
        {"Delete order?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-delete-order-description">
          Confirm you want to delete this order
        </DialogContentText>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onConfirm}>Delete</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
