import React from "react";
import { styled } from "@stitches/react";
import { Container } from "semantic-ui-react";

import { NewOrderProvider } from "./NewOrderContext";
import { NewOrderForm } from "./NewOrderForm";
import { Typography } from "@material-ui/core";

export const NewOrderView: React.FC<any> = () => {
  return (
    <NewOrderProvider>
      <Container
        style={{
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
        }}
      >
        <StyledContainer text style={{ marginTop: "3rem", padding: "0" }}>
          <Typography variant="h3">Schedule a delivery</Typography>
          <Typography variant="body1" style={{ margin: "1rem 0" }}>
            If you are a new customer we will contact you about pricing after
            schedling your service.
          </Typography>
          <NewOrderForm />
        </StyledContainer>
      </Container>
    </NewOrderProvider>
  );
};

const StyledContainer = styled(Container, {
  margin: "2rem 0",
});
