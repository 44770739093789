import React from "react";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { styled } from "@stitches/react";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import { IContactDetailsFields } from "../types";
import { useNewOrderContext } from "../NewOrderContext";

interface ContactDetailsFormProps {
  onSubmit: (formData: IContactDetailsFields) => void;
  onCancel: () => void;
}

export const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { formData } = useNewOrderContext();

  const getResetValues = (): IContactDetailsFields => {
    const { name, phoneNumber, address, address2, city, zipCode } = formData;
    return {
      name: name || "",
      phoneNumber: phoneNumber || "",
      address: address || "",
      address2: address2 || "",
      city: city || "",
      zipCode: zipCode || "",
    };
  };

  const handleSubmit = (values: IContactDetailsFields) => {
    onSubmit(values);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getResetValues()}
      validationSchema={object({
        name: string().required("Required"),
        phoneNumber: string()
          .required("Required")
          .matches(
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
            "Not a valid phone number"
          ),
        address: string().required("Required"),
        address2: string(),
        city: string().required("Required"),
        zipCode: string().required("Required"),
      })}
    >
      {({ values, errors, touched, setValues }) => (
        <StyledForm>
          <StyledFieldWrapper>
            <Field
              name="name"
              component={TextField}
              fullWidth
              disabled={false}
              label="Contact name"
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Field
              name="phoneNumber"
              component={TextField}
              fullWidth
              disabled={false}
              label="Phone Number"
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Field
              name="address"
              component={TextField}
              fullWidth
              disabled={false}
              label="Address"
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Field
              name="address2"
              component={TextField}
              fullWidth
              disabled={false}
              label="Address 2"
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Field
              name="city"
              component={TextField}
              fullWidth
              disabled={false}
              label="City"
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Field
              name="zipCode"
              component={TextField}
              fullWidth
              disabled={false}
              label="Zip code"
            />
          </StyledFieldWrapper>

          <StyledButtonWrapper>
            <Button
              style={{ marginRight: "1rem" }}
              variant="outlined"
              color="primary"
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={() => {
                setValues(getResetValues());
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </StyledButtonWrapper>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledButtonWrapper = styled("div", {
  marginTop: "2rem",
});

const StyledFieldWrapper = styled("div", {
  marginBottom: "1rem",
});

const StyledForm = styled(Form, {
  width: "100%",
});
