import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@stitches/react";
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment-timezone";

import { AccordionSummary } from "../AccordionSummary";
import { useNewOrderContext } from "../NewOrderContext";
import {
  IAvailableDeliveryTimes,
  OrderModel,
} from "../../../models/OrderModel";
import { PHONE_NUMBER } from "../../../constants/company";

import { DeliveryTimeForm, IDeliveryTimeFields } from "./DeliverTimeForm";

export const DeliveryTime: React.FC<any> = () => {
  const [
    availableDeliveries,
    setAvailableDeliveries,
  ] = useState<IAvailableDeliveryTimes>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    isDeliveryDetailsValid,
    isDeliveryTimeValid,
    setDeliveryTime,
    formData: { startTime },
  } = useNewOrderContext();

  const isComplete = isDeliveryTimeValid;

  const handleSubmit = (values: IDeliveryTimeFields) => {
    const delivery = availableDeliveries.find(
      (d) => d.startTime === values.deliveryDay
    );
    if (delivery) {
      setDeliveryTime({
        startTime: delivery.startTime,
        driverId: delivery.driverId,
      });
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // get available delivery times
    (async () => {
      try {
        setAvailableDeliveries(await OrderModel.getAvailableTimes());
      } catch (err: any) {
        console.error(err);
        setHasServerError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  /**
   * Automatically open if contact details was just finished and this is unfinished
   */
  useEffect(() => {
    if (isDeliveryDetailsValid && !isDeliveryTimeValid) {
      setIsOpen(true);
    }
  }, [isDeliveryDetailsValid, isDeliveryTimeValid]);

  return (
    <Accordion expanded={isOpen}>
      <AccordionSummary
        expanded={isOpen}
        onClick={() => setIsOpen(true)}
        title="Delivery Time"
        description={
          isComplete
            ? `Delivery on ${moment(startTime)
                .tz("America/Los_Angeles")
                .format("ddd, MMMM Do, YYYY")}`
            : "Please choose a delivery time."
        }
        isComplete={isComplete}
      />
      <StyledContent>
        {isLoading && (
          <StyledCircularProgressContainer>
            <CircularProgress />
          </StyledCircularProgressContainer>
        )}
        {!isLoading && !hasServerError && availableDeliveries.length > 0 && (
          <DeliveryTimeForm
            availableDeliveries={availableDeliveries}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        )}

        {!isLoading && !hasServerError && availableDeliveries.length === 0 && (
          <Alert severity="error">
            <AlertTitle>We're all booked up!</AlertTitle>
            We don't have any deliveries available at this time. Please try
            again later or call us at {PHONE_NUMBER}
          </Alert>
        )}

        {!isLoading && hasServerError && (
          <Alert severity="error">
            <AlertTitle>Server Error</AlertTitle>
            Oops! We were unable to grab available delivery times. Please try
            again later or call us at {PHONE_NUMBER}
          </Alert>
        )}
      </StyledContent>
    </Accordion>
  );
};

const StyledContent = styled(AccordionDetails, {
  paddingLeft: "2rem !important",
});

const StyledCircularProgressContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
});
