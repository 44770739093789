import React, { useEffect, useState } from "react";
import { Form, Button, Message, Header } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useParams, Link } from "react-router-dom";

import { useUserContext } from "../../../contexts/UserContext";
import { validatePassword } from "../../../utils/validation";

import { ResetPasswordViewParams } from "../types";
import { PHONE_NUMBER } from "../../../constants/company";

interface FieldTypes {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm: React.FC<any> = () => {
  const { resetPassword } = useUserContext();

  const { email, token } = useParams<ResetPasswordViewParams>();

  const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(
    null
  );
  const [hasServerSuccess, setHasServerSuccess] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    setValue,
    trigger,
  } = useForm<FieldTypes>();

  const onSubmit = async ({ password }: FieldTypes) => {
    try {
      await resetPassword({
        email: decodeURIComponent(email),
        token,
        password,
      });
      setHasServerSuccess(true);
    } catch (e) {
      setServerErrorMessage(e.message);
    }
  };

  useEffect(() => {
    register("password", {
      validate: (value) => validatePassword(value),
    });
    register("confirmPassword", {
      validate: (value) => value === watch("password"),
    });
  }, []);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      error={serverErrorMessage !== null}
      success={hasServerSuccess}
    >
      <Form.Input
        fluid
        label="Password"
        placeholder="Enter a new password"
        type="password"
        name="password"
        onChange={async (e) => {
          setValue("password", e.target.value);
          // only update validation on change if the user corrected their email address
          if (validatePassword(e.target.value)) {
            await trigger("password");
          }
        }}
        onBlur={(e: any) =>
          setValue("password", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.password
            ? {
                content: "Password must be 6 characters long",
                pointing: "above",
              }
            : undefined
        }
      />
      <Form.Input
        fluid
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Confirm your new password"
        type="password"
        onChange={async (e) => {
          setValue("confirmPassword", e.target.value);
          // only update validation on change if the user corrected their password
          if (e.target.value === watch("password")) {
            await trigger("confirmPassword");
          }
        }}
        onBlur={(e: any) =>
          setValue("confirmPassword", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.confirmPassword
            ? {
                content: "Passwords must match",
                pointing: "above",
              }
            : undefined
        }
      />
      <Button type="submit" disabled={!isValid || isSubmitting}>
        Submit
      </Button>
      <Message error header="Server Error" content={serverErrorMessage} />
      <Message success>
        <Header as="h3">Password Reset Successful!</Header>
        Click this link to <Link to="/login">Sign in</Link>
      </Message>
    </Form>
  );
};

export default ResetPasswordForm;
