import React, { useState } from "react";
import { Accordion, AccordionDetails } from "@material-ui/core";

import { AccordionSummary } from "../AccordionSummary";
import { useNewOrderContext } from "../NewOrderContext";
import { IContactDetailsFields } from "../types";

import { ContactDetailsForm } from "./ContactDetailsForm";
import { styled } from "@stitches/react";

export const ContactDetails: React.FC<any> = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { formData, setContactDetails, isContactDetailsValid } =
    useNewOrderContext();

  const { name, city } = formData;
  const isComplete = isContactDetailsValid;

  const handleSubmit = (formData: IContactDetailsFields) => {
    setContactDetails(formData);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Accordion defaultExpanded={true} expanded={isOpen}>
      <AccordionSummary
        expanded={isOpen}
        isComplete={isComplete}
        onClick={() => setIsOpen(true)}
        title="Contact Details"
        description={
          isComplete
            ? `Delivering to ${name} in ${city}, CA`
            : "Please fill out contact details before completing your order."
        }
      />

      <StyledAccordionDetails>
        <ContactDetailsForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </StyledAccordionDetails>
    </Accordion>
  );
};

const StyledAccordionDetails = styled(AccordionDetails, {
  width: "100%",
});
