import React from "react";
import { styled } from "@stitches/react";
import { Item } from "semantic-ui-react";
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Typography,
} from "@material-ui/core";
import {
  ExpandMore,
  CheckCircleRounded,
  Error,
  EditRounded,
} from "@material-ui/icons";

interface AccordionTitleProps extends AccordionSummaryProps {
  title: string;
  description: string;
  isComplete: boolean;
  expanded: boolean;
}

export const AccordionSummary: React.FC<AccordionTitleProps> = ({
  expanded,
  onClick,
  title,
  description,
  isComplete,
}) => {
  return (
    <StyledAccordionSummary
      classes={{
        root: "accordion-summary-root",
        expandIcon: "accordion-summary-expandIcon",
      }}
      expandIcon={<ExpandMore />}
      onClick={onClick}
      expanded={expanded}
    >
      <SContentWrapper>
        {!expanded && isComplete && <CheckCircleRounded color="primary" />}
        {!expanded && !isComplete && <Error color="error" />}
        {expanded && <EditRounded />}
        <STextWrapper>
          <Typography variant="h5">{title}</Typography>
          {!expanded && (
            <Typography variant="subtitle1">{description}</Typography>
          )}
        </STextWrapper>
      </SContentWrapper>
    </StyledAccordionSummary>
  );
};

const StyledAccordionSummary = styled(MuiAccordionSummary, {
  variants: {
    expanded: {
      true: {
        "&.accordion-summary-root": {
          cursor: "default !important",
        },
        "& .accordion-summary-expandIcon": {
          cursor: "default !important",
        },
      },
      false: {},
    },
  },
});

const SContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const STextWrapper = styled("div", {
  marginLeft: "1rem !important",
});
