import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { IAdminLocation } from "../types";
import { DeliveryLocationModel } from "../../../models/DeliveryLocationModel";

interface ICreateInvoiceModalProps {
  location: IAdminLocation;
  isOpen: boolean;
  onClose: () => void;
}

// TODO: turn this into a form and add validation

export const EditUserLocationModal = ({
  location,
  isOpen,
  onClose,
}: ICreateInvoiceModalProps) => {
  const {
    estimatedMinutes,
    user: { email },
  } = location;

  const [serverErrorMsg, setServerErrorMsg] = useState<string | null>(null);
  const [updatedEstimatedMinutes, setUpdatedEstimatedMinutes] =
    useState<string>(`${estimatedMinutes || 120}`);

  const handleSubmit = async () => {
    const updatedLocation = { ...location };
    updatedLocation.estimatedMinutes = parseInt(updatedEstimatedMinutes);
    try {
      await DeliveryLocationModel.put(updatedLocation as any);
      onClose();
    } catch (e: any) {
      console.log(e.response.data);
      setServerErrorMsg(e.response.data);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Invoice</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
        >
          <Box width="14rem">Customer email: {email}</Box>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Estimated delivery time (mins)"
            type="text"
            value={updatedEstimatedMinutes}
            onChange={(e) => setUpdatedEstimatedMinutes(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Send it!
        </Button>
      </DialogActions>
      {serverErrorMsg && <Alert severity="error">{serverErrorMsg}</Alert>}
    </Dialog>
  );
  return null;
};
