import validator from "validator";
import { VALID_ZIP_CODES } from "../constants";

export const validateEmail = (value?: any) => {
  return value && validator.isEmail(value);
};

export const validatePassword = (value?: any) => {
  return value && value.length >= 6;
};

export const validatePhoneNumber = (value?: any) => {
  return value && validator.isMobilePhone(value);
};

export const validateZipCode = (value?: any) => {
  return value && VALID_ZIP_CODES.includes(value);
};
