import React from "react";
import { Container, Header } from "semantic-ui-react";

import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordView: React.FC = () => {
  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container text>
        <Header as="h1">Reset Password</Header>
        <ResetPasswordForm />
      </Container>
    </Container>
  );
};

export default ResetPasswordView;
