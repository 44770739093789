import React from "react";
import { Container, Header } from "semantic-ui-react";

export const NotFoundView: React.FC = () => {
  return (
    <Container
      text
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Header as="h1">404 Page Not Found</Header>
    </Container>
  );
};
