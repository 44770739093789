import React, { useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";
import { SmartWaterModel, ISmartWaterUser } from "../../models/SmartWaterModel";
import { SmartWaterUsersTable } from "./SmartWaterUsersTable";

export const SmartWaterView: React.FC = () => {
  const [customer, setCustomers] = useState<ISmartWaterUser[]>([]);

  useEffect(() => {
    (async () => {
      const data = await SmartWaterModel.getAll();
      setCustomers(data);
    })();
  }, []);


  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md" style={{ padding: "2rem 0" }}>
        <Typography
          variant="h3"
          style={{ fontSize: "1.4rem", marginBottom: "1rem" }}
        >
          Smart Water Customers
        </Typography>
        <SmartWaterUsersTable />
      </Container>
    </Container>
  );
};