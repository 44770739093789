import React, { useContext, useState } from "react";
import api from "../../utils/api";
import { SubmitHandler } from "react-hook-form";
import IFieldTypes from "./CustomerCalculatorForm"

interface ICustomerEstimateContext {
    address: any;
    estimateValue: number;
    onSubmit: (FieldValues: any) => Promise<number>;
}

export const CustomerEstimateContext = React.createContext<Partial<ICustomerEstimateContext>>({});

export const useCustomerEstimateContext = () => {
    const context = useContext(CustomerEstimateContext);
    if (context === undefined) {
        throw new Error("useUserContext must be used within a UserProvider");
      }
      return context as ICustomerEstimateContext;
};

export const CustomerEstimateProvider: React.FC = ({ children }) => {
    const [address, setAddress] = useState<any>("");
    const [estimateValue, setEstimateValue] = useState<any>(0);

    const getEstimate = async (address: any) => {
        return api.get("/customerCalculator",{params: {address: address}}).then(res => {
          console.log(res.data.finalEstimate);
          const estimateValue = res.data.finalEstimate;
          return estimateValue;
        })
      };

    const onSubmit: SubmitHandler<typeof IFieldTypes> = async ({ address }: any, e: any) => {
        e.preventDefault();
        console.log("This is the address submitted from the React Form: " + address);
        const estimateValue = await getEstimate(address);
        console.log("Successful onSubmit when Clicked");
        console.log("The Customer Estimate: $" + estimateValue);
        setEstimateValue(estimateValue);
        return estimateValue;
      };


    const value: ICustomerEstimateContext = {
        address,
        estimateValue,
        onSubmit,
    };

    return (
        <CustomerEstimateContext.Provider value = {value}>
            {children}
        </CustomerEstimateContext.Provider>
    );
};