import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { MdInvertColors } from "react-icons/md";

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <MdInvertColors className="navbar-icon" />
              LUNDE WATER, LLC
            </Link>
          </div>
          <small className="website-rights">Lunde Water, LLC © 2023</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
