import React, { useEffect, useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useUserContext } from "../../../contexts/UserContext";
interface FieldTypes {
  email: string;
  password: string;
}

const LoginForm: React.FC<any> = () => {
  const { isAdmin, login, isAuthenticated } = useUserContext();
  const history = useHistory();

  const onSubmit = async ({ email, password }: FieldTypes) => {
    const user = await login({ email, password });
    if (user && user.role === "admin") {
      history.push("/admin/orders");
    } else if (user) {
      history.push("/orders");
    } else {
      setServerError(true);
    }
  };

  useEffect(() => {
    if (isAdmin && isAuthenticated) {
      history.push("/admin/orders");
    } else if (isAuthenticated) {
      history.push("/orders");
    }
  }, [isAdmin, isAuthenticated, history]);

  const [hasServerError, setServerError] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm<FieldTypes>();

  return (
    <Form onSubmit={handleSubmit(onSubmit)} error={hasServerError}>
      <Form.Input
        fluid
        label="Email Address"
        placeholder="Email Address"
        name="email"
        onChange={async (e) => {
          setValue("email", e.target.value);
        }}
        onBlur={(e: any) =>
          setValue("email", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.email
            ? {
                content: "Please enter your email address",
                pointing: "above",
              }
            : undefined
        }
      />
      <Form.Input
        fluid
        label="Password"
        placeholder="Enter your password"
        type="password"
        name="password"
        onChange={async (e) => {
          setValue("password", e.target.value);
        }}
        onBlur={(e: any) =>
          setValue("password", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.password
            ? {
                content: "Password must be 6 characters long",
                pointing: "above",
              }
            : undefined
        }
      />
      <Button type="submit" disabled={!isValid || isSubmitting}>
        Submit
      </Button>
      <Message
        error
        header="Invalid Credentials"
        content={`You entered an incorrect username or password`}
      />
    </Form>
  );
};

export default LoginForm;
