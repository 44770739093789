import React from "react";
import "./HeroSection.css";
import { Button } from "../button/Button";

function HeroSection({
  questionsPost,
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  buttonLink,
  img,
  alt,
  imgStart,
  onClick,
  anchorParams={},
  onClickBack,
  questionIndex,
  blackBg,
}) {
  return (
    <>
      <div
        style={{
          backgroundSize: "cover",
          backgroundColor: blackBg ? blackBg : "#1c2237",
        }}
        className={lightBg ? "home__hero-section" : "home__hero-section darkBg"}
      >
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: imgStart === "start" ? "row-reverse" : "row",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                {topLine && (
                    <div className="top-line">{topLine}</div>
                )}
                {headline && (
                  <h1 className={lightText ? "heading" : "heading dark"}>
                    {headline}
                  </h1>
                )}
                <div
                  className={
                    lightTextDesc
                      ? "home__hero-subtitle"
                      : "home__hero-subtitle dark"
                  }
                >
                  {description}
                </div>
                {buttonLabel && (
                  <a href={buttonLink} {...anchorParams}>
                    <Button
                      buttonSize="btn--wide"
                      buttonColor="green"
                      onClick={onClick}
                    >
                      {buttonLabel}
                    </Button>
                  </a>
                )}
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src={img} alt={alt} className="home__hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
