import React from "react";
import { Grid, Header, Image, Segment } from "semantic-ui-react";

function HeroSection() {
  return (
    <>
      <Segment style={{ padding: "8em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Potable Water Delivery at its Best
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                We provide peace of mind. Our drivers safely operate our fleet
                of semi tankers to deliver your water in a professional and
                timely manner. You can rest assured that your product will
                arrive as promised.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                Our water is local, tested, and naturally pure. Lunde Water is
                sourced locally in Acton, CA. It is tested every week by AVEK &
                yearly by Eurofins Scientific. Lunde Water is naturally
                filtered by the earth and ready for consumption the moment it's
                pumped from the ground.
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              <Image
                bordered
                rounded
                size="large"
                src="/images/LundeTruck.png"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}

export default HeroSection;
