import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { useUserContext } from "../contexts/UserContext";
import UnauthorizedModal from "../components/modal/UnauthorizedModal";

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  children,
  ...rest
}: any) => {
  const { isLoadingUser, isAuthenticated } = useUserContext();

  const isProtectedRouteVisible = !isLoadingUser && isAuthenticated;

  if (!isLoadingUser && !isAuthenticated) {
    return (
      // TODO: Replace with a container
      <div style={{ height: "75vh" }}>
        <UnauthorizedModal />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => isProtectedRouteVisible && <Component {...props} />}
    >
      {children}
    </Route>
  );
};

export default PrivateRoute;
