import { AxiosResponse } from "axios";

const TOKEN_LOCALSTORAGE_KEY = "token";

export const setAuthToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
  } else {
    localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
  }
};

export const getAuthToken = () => {
  return localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
};

export const getTokenFromResponse = (
  res: AxiosResponse<any>
): string | null => {
  const bearer = res.headers.authorization;
  if (typeof bearer === "string") {
    const token = bearer.split("Bearer ")[1].trim();
    return token;
  }
  return null;
};
