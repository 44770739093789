import React, { useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { useForm } from "react-hook-form"
import api from "../../utils/api";
import { useCustomerEstimateContext } from "./CustomerEstimateContext"

export interface IFieldTypes {
  address: string;
};

const CustomerCalculatorForm: React.FC<any> = () => {

  const {
  address,
  onSubmit,
  } = useCustomerEstimateContext();


  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
    setValue,
  } = useForm<IFieldTypes>();

  const [hasServerError, setServerError] = useState<boolean>(false);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} error={hasServerError}>
      <Form.Input
        fluid
        label="Address"
        placeholder="Address"
        name="address"
        onChange={async (e) => {
          setValue("address", e.target.value);
        }}
        error={
          errors.address
            ? {
                content: "Please enter your address",
                pointing: "above",
              }
            : undefined
        }
      />
      <Button type="submit">
        Submit
      </Button>
      <Message
        error
        header="Invalid Address"
        content={`You entered an invalid address`}
      />
    </Form>
  );
};

export default CustomerCalculatorForm;
