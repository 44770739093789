import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { round } from "lodash";

import { useAdminOrdersContext } from "../AdminOrdersContext";
import { IAdminOrder } from "../types";
import { OrderModel } from "../../../models/OrderModel";

interface ICreateInvoiceModalProps {
  order: IAdminOrder;
  isOpen: boolean;
  onClose: () => void;
}

// TODO: turn this into a form and add validation
export const CreateInvoiceModal = ({
  order,
  isOpen,
  onClose,
}: ICreateInvoiceModalProps) => {
  const { setIsSendingInvoice } = useAdminOrdersContext();

  const {
    deliveryLocationId: { deliveryCost },
    userId: { email },
  } = order;

  const [serverErrorMsg, setServerErrorMsg] = useState<string | null>(null);
  const [updatedPrice, setUpdatedPrice] = useState<string>(
    `${deliveryCost || 0}`
  );

  const handleSubmit = async () => {
    const updatedOrder = { ...order };
    updatedOrder.deliveryLocationId.deliveryCost = round(
      parseFloat(updatedPrice),
      2
    );

    try {
      await OrderModel.createInvoice({
        ...order,
      });
      onClose();
      setIsSendingInvoice(true);
    } catch (e: any) {
      console.log(e.response.data);
      setServerErrorMsg(e.response.data);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Invoice</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
        >
          <Box width="14rem">Customer email: {email}</Box>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="total"
            type="text"
            value={updatedPrice}
            onChange={(e) => setUpdatedPrice(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Send it!
        </Button>
      </DialogActions>
      {serverErrorMsg && <Alert severity="error">{serverErrorMsg}</Alert>}
    </Dialog>
  );
};
