import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { styled } from "@stitches/react";
import "@fontsource/roboto";

import { UserProvider } from "../contexts/UserContext";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import RegisterView from "../views/RegisterView";
import LoginView from "../views/LoginView";
import ForgotView from "../views/ForgotPasswordView";
import { NewOrderView } from "../views/NewOrderView";
import ResetView from "../views/ResetPasswordView";
import { OrdersView } from "../views/OrdersView";
import { AdminOrdersView } from "../views/AdminOrdersView";

import { SmartWaterView } from "../views/SmartWaterView/SmartWaterView";
import CustomerCalculatorView from "../views/CustomerCalculatorView/CustomerCalculatorView";
// TODO: move these to client/views/
import Homepage from "../components/semantic_ui/Homepage/Homepage";
import Aboutpage from "../components/semantic_ui/Aboutpage/Aboutpage";

import PrivateRoute from "./PrivateRoute";
import { AdminRoute } from "./AdminRoute";
import { AdminDeliveriesView } from "../views/AdminDeliveriesView";
import { AdminNewUserView } from "../views/AdminNewUserView";
import { AdminUsersView } from "../views/AdminUsersView";
import { NotFoundView } from "../views/NotFoundView";

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <AppWrapper>
          <NavBar />
          <MainWrapper>
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/about-us" component={Aboutpage} />
              {/* The component below has some nice hero sections we can steal for the homepage */}
              <Route exact path="/register" component={RegisterView} />
              <Route exact path="/login" component={LoginView} />
              <Route exact path="/forgotpassword" component={ForgotView} />
              <Route
                exact
                path="/resetpassword/:email/:token"
                component={ResetView}
              />

              <Route exact path="/smartwater" component={SmartWaterView} />
              <Route exact path="/customercalculator" component={CustomerCalculatorView} />

              <PrivateRoute exact path="/orders" component={OrdersView} />
              <PrivateRoute exact path="/neworder" component={NewOrderView} />
              <AdminRoute
                exact
                path="/admin/orders"
                component={AdminOrdersView}
              />
              <AdminRoute
                exact
                path="/admin/deliveries"
                component={AdminDeliveriesView}
              />
              <AdminRoute
                exact
                path="/admin/users"
                component={AdminUsersView}
              />
              <AdminRoute
                exact
                path="/admin/new-user"
                component={AdminNewUserView}
              />
              <Route>
                <NotFoundView />
              </Route>
            </Switch>
          </MainWrapper>
          <Footer />
        </AppWrapper>
      </Router>
    </UserProvider>
  );
};

export default App;

const AppWrapper = styled("div", { height: "100%", minHeight: "100vh", display: "flex", flexDirection: "column" });
const MainWrapper = styled("div", { height: "100%", flexGrow: 1 });
