import React, { createContext, useMemo, useCallback, useContext, useEffect, useState } from "react";
import merge from "lodash/merge";

import {
  IContactDetailsFields,
  IDeliveryDetailsFields,
  INewOrderFields,
} from "./types";
import {
  IDeliveryTime,
  IOrderModel,
  OrderModel,
} from "../../models/OrderModel";
import { DeliveryLocationModel } from "../../models/DeliveryLocationModel";
import { useUserContext } from "../../contexts/UserContext";

interface INewOrderContext {
  setContactDetails: (fields: IContactDetailsFields) => void;
  setDeliveryDetails: (fields: IDeliveryDetailsFields) => void;
  setDeliveryTime: (fields: IDeliveryTime) => void;
  formData: INewOrderFields;
  isDeliveryDetailsValid: boolean;
  isContactDetailsValid: boolean;
  isDeliveryTimeValid: boolean;
  isLoadingUserData: boolean;
  submitNewOrder: () => Promise<IOrderModel>;
}

const INITIAL_FORM_DATA_STATE: INewOrderFields = {
  // contact details
  name: "",
  phoneNumber: "",
  address: "",
  address2: "",
  city: "",
  zipCode: "",
  // delivery details
  isContactPersonRequired: "",
  isGpsAccurate: "",
  gpsInaccurateDescription: "",
  isGatedProperty: "",
  gatedPropertyDetails: "",
  tankConnectionDetails: "",
  tankDistance: "",
  // delivery time
  driverId: "",
  startTime: "",
};

export const NewOrderContext = createContext<Partial<INewOrderContext>>({});

export const useNewOrderContext = () => {
  const context = useContext(NewOrderContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context as INewOrderContext;
};

export const NewOrderProvider: React.FC = ({ children }) => {
  const [isDeliveryDetailsValid, setIsDeliveryDetailsValid] = useState(false);
  const [isContactDetailsValid, setIsContactDetailsValid] = useState(false);
  const [isDeliveryTimeValid, setIsDeliveryTimeValid] = useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [formData, setFormDataState] = useState<INewOrderFields>(
    {...INITIAL_FORM_DATA_STATE}
  );
  const { user } = useUserContext();

  const setFormData = useCallback((fields: Partial<INewOrderFields>) => {
    setFormDataState(merge({...formData}, fields));
  }, [formData]);

  const value: INewOrderContext = useMemo(() => {
    const setContactDetails = (fields: IContactDetailsFields) => {
      setFormData(fields);
      setIsContactDetailsValid(true);
    };

    const setDeliveryDetails = (fields: IDeliveryDetailsFields) => {
      setFormData(fields);
      setIsDeliveryDetailsValid(true);
    };

    const setDeliveryTime = (fields: IDeliveryTime) => {
      setFormData(fields);
      setIsDeliveryTimeValid(true);
    };

    /**
     * @throws {Error}
     */
    const submitNewOrder = async (): Promise<IOrderModel> => {
      const newOrder = await OrderModel.create({
        ...formData,
        isContactPersonRequired: formData.isContactPersonRequired === "true",

        isGpsAccurate: formData.isGpsAccurate === "true",
        gpsInaccurateDescription:
          formData.isGpsAccurate === "false"
            ? formData.gpsInaccurateDescription
            : undefined,

            isGatedProperty: formData.isGatedProperty === "true",
            gatedPropertyDetails:
              formData.isGatedProperty === "true"
                ? formData.gatedPropertyDetails
                : undefined,
      });
      return newOrder;
    };

    return {
      setContactDetails,
      setDeliveryDetails,
      setDeliveryTime,
      submitNewOrder,
      formData,
      isDeliveryDetailsValid,
      isContactDetailsValid,
      isDeliveryTimeValid,
      isLoadingUserData,
    };
  }, [
    setFormData,
    formData,
    isDeliveryTimeValid,
    isContactDetailsValid,
    isDeliveryDetailsValid,
    isLoadingUserData,
  ]);

  // load delivery location info -- exists if they've place order
  useEffect(() => {
    (async () => {
      const location = await DeliveryLocationModel.get();
      if (location) {
        setFormData({
          address: location.address,
          address2: location.address2,
          city: location.city,
          gatedPropertyDetails: location.gatedPropertyDetails,
          gpsInaccurateDescription: location.gpsInaccurateDescription,
          isContactPersonRequired: `${location.isContactPersonRequired}` as any,
          isGatedProperty: `${location.isGatedProperty}` as any,
          isGpsAccurate: `${location.isGpsAccurate}` as any,
          name: location.name,
          phoneNumber: location.phoneNumber,
          tankConnectionDetails: location.tankConnectionDetails,
          tankDistance: location.tankDistance,
          zipCode: location.zipCode,
        });
      } else {
        setFormDataState({...INITIAL_FORM_DATA_STATE});
      }
      setIsLoadingUserData(false);
    })();
  }, [user]);

  return (
    <NewOrderContext.Provider value={value}>
      {children}
    </NewOrderContext.Provider>
  );
};
