import axios from "axios";

import { getAuthToken, getTokenFromResponse, setAuthToken } from "./auth";

const api = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// if 401 (unauthorized), redirect to login and unset the session token
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401 && window.location.pathname !== "/login") {
      window.location.href = "/login";
      setAuthToken(null);
    }
    return Promise.reject(err);
  }
);

// request interceptor to add the auth token header to requests
api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// if server returns a token, save it
api.interceptors.response.use(
  (res) => {
    const responseToken = getTokenFromResponse(res);
    if (responseToken !== null) {
      setAuthToken(responseToken);
    }
    return res;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

export default api;
