import React, {useEffect, useState, useCallback} from "react";
import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";

import {AdminUsersTableRow} from "./AdminUsersTableRow";
import {AdminModel} from "../../../models/AdminModel";
import {IUser} from "../../../types";

export const AdminUsersTable: React.FC = () => {
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [search, setSearch] = useState<string>("");

  const reloadUsers = async () => {
    const users = await AdminModel.getAllUsers();
    setUsersData(users);
    // I think its better to show most recent users. In the future make a button to toggle this?
    // setUsersData(users.sort((x,y) => {
    //   if (x?.email < y?.email) { return -1; }
    //   if (x?.email > y?.email) { return 1; }
    //   return 0;
    // }));
  }

  const searchUsers = useCallback((user: IUser) => {
    if (user.email.toLowerCase().includes(search.toLowerCase())) {
      return true
    }
  }, [search])

  useEffect(() => {
    (async () => {
      await reloadUsers()
    })();
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="search-users"
          label="Search Users"
          type="search"
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="orders table">
          <TableHead>
            <TableRow>
              <TableCell>email</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData.filter(searchUsers).map((data: IUser, index: number) => (
              <AdminUsersTableRow key={index} user={data} reloadUsers={reloadUsers} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

