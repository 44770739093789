import api from "../utils/api";
import { IAdminOrder } from "../views/AdminOrdersView/types";

const ORDERS_BASE_PATH = "/orders";

export interface IOrderModel {
  // isContactPersonRequired: boolean;
  // isGpsAccurate: boolean;
  // gpsInaccurateDescription?: string;
  // isGatedProperty: boolean;
  // gatedPropertyDetails?: string;
  // tankConnectionDetails: string;
  // tankDistance: string;
}

export interface IOrderWithLocation {
  // contact details
  name: string;
  phoneNumber: string;
  address: string;
  address2?: string;
  city: string;
  zipCode: string;
  // delivery details
  isContactPersonRequired: boolean;
  isGpsAccurate: boolean;
  gpsInaccurateDescription?: string;
  isGatedProperty: boolean;
  gatedPropertyDetails?: string;
  tankConnectionDetails: string;
  tankDistance: string;
  // delivery time
  driverId: string;
  startTime: string;
}

export interface IDeliveryTime {
  startTime: string;
  driverId: string;
}

export type IAvailableDeliveryTimes = IDeliveryTime[];

export const OrderModel = {
  delete: async (orderId: string) => {
    try {
      await api.delete(`${ORDERS_BASE_PATH}/${orderId}`);
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  /**
   * @param orderProp {IOrderModel}
   * @throws {Error}
   */
  create: async (orderArgs: IOrderWithLocation): Promise<IOrderModel> => {
    try {
      const response = await api.post<IOrderModel>(ORDERS_BASE_PATH, orderArgs);
      return response.data;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  /**
   * @throws {Error}
   */
  getAll: async (): Promise<IOrderWithLocation[]> => {
    try {
      const response = await api.get<IOrderWithLocation[]>(
        `${ORDERS_BASE_PATH}`
      );
      return response.data || [];
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  /**
   * @throws {Error}
   */
  getAvailableTimes: async (): Promise<IAvailableDeliveryTimes> => {
    try {
      const response = await api.get<IAvailableDeliveryTimes>(
        `${ORDERS_BASE_PATH}/availableTimes`
      );
      return response.data || [];
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  createInvoice: async (order: IAdminOrder) => {
    try {
      const response = await api.post<IAdminOrder>(
        `admin/orders/${order._id}/invoice`,
        {
          user: order.userId,
          deliveryCost: order.deliveryLocationId.deliveryCost,
        }
      );
      return response.data || [];
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
};
