import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";

import { AdminDeliveriesTableRow } from "./AdminDeliveriesTableRow";
import { DeliveryLocationModel } from "../../../models/DeliveryLocationModel";

export const AdminDeliveriesTable: React.FC = () => {
  const [deliveryData, setDeliveryData] = useState<any>([]);
  const [search, setSearch] = useState<string>("");

  const searchDeliveries = useCallback((location: any) => {
    const email = location.user.email.toLowerCase();
    const address = location.address.toLowerCase();
    if (email.includes(search.toLowerCase())
    || address.includes(search.toLowerCase())
    ) {
      return true
    }
  }, [search])

  useEffect(() => {
    (async () => {
      const deliveryLocations = await DeliveryLocationModel.getAll();
      // @ts-ignore
      setDeliveryData((deliveryLocations || []).filter((location: any) => location.user));
    })();
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="search-deliveries"
          label="Search Deliveries"
          type="search"
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="orders table">
          <TableHead>
            <TableRow>
              <TableCell>email</TableCell>
              <TableCell align="center">Address</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Length (mins)</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveryData.filter(searchDeliveries).map((data: any, index: number) => (
              <AdminDeliveriesTableRow location={data} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
