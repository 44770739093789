/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

import ResponsiveContainer from "../ResponsiveLayout/ResponsiveContainer";
import AboutSection1 from "../../hero/AboutSection1";

const Aboutpage = () => (
  <ResponsiveContainer>
    <AboutSection1 />
  </ResponsiveContainer>
);

export default Aboutpage;
