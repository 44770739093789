import React, { useEffect, useState } from "react";
import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";


import {ISmartWaterLocation} from "./types";
import {SmartWaterModel, ISmartWaterUser} from "../../models/SmartWaterModel"
export const SmartWaterUsersTable: React.FC = () => {
  const [smartWaterUserData, setSmartWaterUserData] = useState<ISmartWaterUser[]>([]);

  useEffect(() => {
    (async () => {
      const smartWaterUsers = await SmartWaterModel.getAll();
      setSmartWaterUserData(smartWaterUsers);
    })();
  }, []);
  
  return (
    
      <TableContainer component={Paper}>
        <Table aria-label="smart water table">
          <TableHead>
            <TableRow>
              <TableCell>Smart Water ID</TableCell>
              <TableCell align="center">Tank Level</TableCell>
              <TableCell align="center">Days Until Empty</TableCell>
              <TableCell align="center">Average Daily Water Use</TableCell>
              <TableCell align="center">Tank Name</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {smartWaterUserData.map((data: any, index) => (
            <TableRow key={index}>
              <TableCell>{data.smartWaterID}</TableCell>
              <TableCell align="center">{data.tankLevel}</TableCell>
              <TableCell align="center">{data.daysToEmpty}</TableCell>
              <TableCell align="center">{data.dailyWaterUse}</TableCell>
              <TableCell align="center">{data.tankName}</TableCell>
              <TableCell align="right">
              </TableCell>
            </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    
  );
};