import React from "react";
import { Container, Header, Input } from "semantic-ui-react";
import { useCustomerEstimateContext } from "./CustomerEstimateContext"

const CustomerEstimateDisplay: React.FC = () => {
    const { estimateValue } = useCustomerEstimateContext()
    return (
      <Container
        style={{
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
        }}
      >
        <Container text style={{ padding: "0" }}>
          <Header as="h1">Here is your Estimate</Header>
          <Container
            text
            style={{ display: "flex", padding: "0px", marginTop: "2rem" }}
          >
          </Container>
          <Input>${estimateValue}</Input>
        </Container>
      </Container>
    );
  };

export default CustomerEstimateDisplay;