import React from "react";
import {Container, Typography} from "@material-ui/core";
import {Button} from "../../components/button/Button";

import {AdminUsersTable} from "./AdminUsersTable";

export const AdminUsersView: React.FC = () => {
  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2em 0",
      }}
    >
      <a
        href="/admin/new-user"
      >
        <Button
          buttonSize="btn--wide"
          buttonColor="green"
        >
          Create a new user
        </Button>
      </a>
      <Container maxWidth="md" style={{padding: "2rem 0"}}>
        <Typography
          variant="h3"
          style={{fontSize: "1.4rem", marginBottom: "1rem"}}
        >
          Customers
        </Typography>
        <AdminUsersTable />
      </Container>
    </Container>
  );
};

