import React from "react";
import { styled } from "@stitches/react";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import moment from "moment-timezone";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { Select } from "formik-material-ui";
import { uniqWith } from "lodash";

import { IAvailableDeliveryTimes } from "../../../models/OrderModel";
import { useNewOrderContext } from "../NewOrderContext";

const TZ = "America/Los_Angeles"

interface IDeliveryTimeForm {
  availableDeliveries: IAvailableDeliveryTimes;
  onSubmit: (formData: IDeliveryTimeFields) => void;
  onCancel: () => void;
}

type TTimeOfDay = "am" | "pm" | "any";

export interface IDeliveryTimeFields {
  timeOfDay: TTimeOfDay;
  deliveryDay: string | null;
}

const getTimeOfDayFromTimestamp = (timestamp: string): TTimeOfDay => {
  if (timestamp === "") {
    return "any";
  }

  const hour = moment(timestamp).tz(TZ).hour();
  if (hour < 12) {
    return "am";
  }
  return "pm";
};

export const DeliveryTimeForm: React.FC<IDeliveryTimeForm> = ({
  availableDeliveries,
  onSubmit,
  onCancel,
}) => {
  const {
    formData: { startTime },
  } = useNewOrderContext();

  const handleSubmit = (values: IDeliveryTimeFields) => {
    onSubmit(values);
  };
  const handleCancel = () => {
    onCancel();
  };

  const getResetValues = (): IDeliveryTimeFields => ({
    timeOfDay: getTimeOfDayFromTimestamp(startTime),
    deliveryDay: startTime,
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getResetValues()}
      validationSchema={object({
        timeOfDay: string().required("Required"),
        deliveryDay: string().required("Required").min(1),
      })}
    >
      {({ values, setValues, errors }) => {
        const filteredDeliveryDays = availableDeliveries.filter((delivery) => {
          if (values.timeOfDay === "any") {
            return true;
          }
          if (values.timeOfDay === "am") {
            return (
              moment(delivery.startTime).tz(TZ).hour() < 12
            );
          }
          if (values.timeOfDay === "pm") {
            return (
              moment(delivery.startTime).tz(TZ).hour() >= 12
            );
          }
          return false;
        });
        const deduplicatedDeliveryDays = uniqWith(
          filteredDeliveryDays,
          (a, b) => {
            return moment(a.startTime).tz(TZ).isSame(moment(b.startTime).tz(TZ), "date");
          }
        );

        // clear out the deliverytime if the user changed the time of day and that delivery time doesn't exist for that day
        if (
          values.deliveryDay !== "" &&
          filteredDeliveryDays.findIndex(
            (d) => d.startTime === values.deliveryDay
          ) === -1
        ) {
          setValues({
            timeOfDay: values.timeOfDay,
            deliveryDay: "",
          });
        }

        return (
          <StyledForm>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <FormControl style={{ width: "100%" }}>
                  <Field component={Select} name="timeOfDay" disabled={false}>
                    <MenuItem value={"any"}>Any</MenuItem>
                    <MenuItem value={"am"}>AM</MenuItem>
                    <MenuItem value={"pm"}>PM</MenuItem>
                  </Field>
                  <FormHelperText>Time of day</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <FormControl style={{ width: "100%" }}>
                  <Field
                    disabled={deduplicatedDeliveryDays.length === 0}
                    component={Select}
                    name="deliveryDay"
                  >
                    {deduplicatedDeliveryDays.map(({ startTime }) => (
                      <MenuItem value={startTime} key={startTime}>
                        {moment(startTime)
                          .tz(TZ)
                          .format("ddd, MMMM Do, YYYY")}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText error={errors.deliveryDay != null}>
                    Date
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <StyledButtonWrapper>
              <Button
                style={{ marginRight: "1rem" }}
                variant="outlined"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="default"
                onClick={() => {
                  setValues(getResetValues());
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </StyledButtonWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

const StyledButtonWrapper = styled("div", {
  marginTop: "2rem",
});

const StyledForm = styled(Form, {
  width: "100%",
});
