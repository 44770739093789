import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
} from "@material-ui/core";

import { IAdminOrder } from "../types";

interface ICreateInvoiceModalProps {
  order: IAdminOrder;
  isOpen: boolean;
  onClose: () => void;
}

export const OrderDetailsModal = ({
  order,
  isOpen,
  onClose,
}: ICreateInvoiceModalProps) => {
  const {
    deliveryLocationId: {
      isContactPersonRequired,
      address,
      tankConnectionDetails,
      city,
      zipCode,
      gpsInaccurateDescription,
      tankDistance,
      gatedPropertyDetails,
      phoneNumber,
      name,
    },
  } = order;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      aria-labelledby="form-dialog-title"
    >
      <Button
        onClick={onClose}
        style={{ width: "2rem", alignSelf: "flex-end" }}
      >
        X
      </Button>
      <DialogTitle id="form-dialog-title">
        Delivery Location Details
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
        >
          <Box width="14rem">Name: {name}</Box>
          <Box width="14rem">
            Address: {address}, {city}, {zipCode}
          </Box>
          <Box width="14rem">Phone Number: {phoneNumber}</Box>
          <Box width="14rem">
            Contact Person Required: {isContactPersonRequired ? "yes" : "no"}
          </Box>
          <Box width="14rem">
            Gps Description:{" "}
            {gpsInaccurateDescription ? gpsInaccurateDescription : "N/A"}
          </Box>
          <Box width="14rem">
            Gated Property Details:{" "}
            {gatedPropertyDetails ? gatedPropertyDetails : "N/A"}
          </Box>
          <Box width="14rem">
            Tank Distance: {tankDistance ? tankDistance : "N/A"}
          </Box>
          <Box width="14rem">
            Tank Connection Details:{" "}
            {tankConnectionDetails ? tankConnectionDetails : "N/A"}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
