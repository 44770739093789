import React from "react";
import { Container, Header } from "semantic-ui-react";

import { PHONE_NUMBER } from "../../constants/company";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm"; // make dynamic?

const LoginView: React.FC = () => {
  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
      }}
    >
      <Container text style={{ padding: "0", flexBasis: "100%" }}>
        <Header as="h1">Forgot your password?</Header>
        <p> We'll email a link so you can reset it. </p>
        <ForgotPasswordForm />
        <p>If you don't have an email, call our office at {PHONE_NUMBER} </p>
      </Container>
    </Container>
  );
};

export default LoginView;
