import React, {useState} from "react";
import {Button, TableCell, TableRow} from "@material-ui/core";

import {EditUserModal} from "../EditUserModal";
import {IUser} from "../../../types";

interface IAdminUsersTableRowProps {
  user: IUser;
  reloadUsers: () => {};
}

export const AdminUsersTableRow: React.FC<IAdminUsersTableRowProps> = ({
  user,
  reloadUsers,
}) => {
  const [isEditUserModalOpen, setIsEditUserModalOpen] =
    useState(false);

  const handleEditClick = () => {
    setIsEditUserModalOpen(true);
  };

  const handleClose = () => {
    reloadUsers();
    setIsEditUserModalOpen(false);
  };

  if (!user) {
    return null
  }

  const {
    email,
    role,
  } = user;

  return (
    <>
      <EditUserModal
        user={user}
        isOpen={isEditUserModalOpen}
        onClose={handleClose}
      />

      <TableRow>
        <TableCell>{email}</TableCell>
        <TableCell align="center">{role}</TableCell>
        <TableCell align="right">
          <Button onClick={handleEditClick} color="primary" variant="contained">
            Edit
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

