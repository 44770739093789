import React from "react";
import { Container, Header } from "semantic-ui-react";

import { RegisterForm } from "./RegisterForm";

const RegisterView: React.FC = () => {
  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
      }}
    >
      <Container text style={{ padding: "0", flexBasis: "100%" }}>
        <Header as="h1">Sign up</Header>
        <RegisterForm />
      </Container>
    </Container>
  );
};

export default RegisterView;
