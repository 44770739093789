export const homeObjZero = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Water for your Lifestyle",
  // headline: "Lunde Water",
  description:
    "Our business specializes in delivering state certified potable water to hundreds of customers in a safe, timely, and professional manner. We understand that many people prefer the rural lifestyle and rely on hauled water so we do our best to help them continue this unique way of life.",
  // buttonLabel: "Learn More",
  imgStart: "start",
  img: "images/svg-2.svg",
  alt: "Credit Card",
};

export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Water for your Lifestyle",
  headline: "Easy Payments!",
  description: "Use our online payment system!",
  buttonLabel: "Get Started",
  buttonLink: "/register",
  imgStart: "",
  img: "images/svg-1.svg",
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: false,
  blackBg: "#1b1c1d",
  lightText: true,
  lightTextDesc: true,
  topLine: "100% SECURE",
  headline: "Click to schedule a Delivery!",
  description:
    "Pick a date and time that best suits you, or call us at (661)-269-5087 to speak to our customer service representative.",
  buttonLabel: "Get Started",
  buttonLink: "/register",
  imgStart: "start",
  img: "images/svg-7.svg",
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "EASY SETUP",
  headline: "Super fast and simple onboarding process",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  buttonLink: "/register",
  imgStart: "",
  img: "images/svg-5.svg",
  alt: "Vault",
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "DATA ANALYTICS",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  buttonLink: "/register",
  imgStart: "start",
  img: "images/svg-8.svg",
  alt: "Vault",
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  headline: "See what people are saying about us",
  description:
    "Check to see peoples experience with us. Have you recently used our service and would like to share your experience? Please click the button to leave a review.",
  buttonLabel: "Reviews",
  imgStart: "end",
  buttonLink: "https://www.google.com/maps/place/Lunde+Water,+LLC/@34.4571299,-118.1959854,17z/data=!4m8!3m7!1s0x80c2f6b03428d3a3:0x3cc6fd691fbf66fa!8m2!3d34.4571255!4d-118.1934105!9m1!1b1!16s%2Fg%2F1tdn8yvg?entry=ttu",
  anchorParams: { target: "_blank" },
  img: "images/lunde-water-truck.jpg",
  alt: "Reviews",
}
