import api from "../utils/api";

const DELIVERY_LOCATION_BASE_PATH = "/deliverylocation";

export interface IDeliveryLocationModel {
  _id: string;
  address: string;
  address2?: string;
  city: string;
  estimatedMinutes: number;
  gatedPropertyDetails?: string;
  gpsInaccurateDescription?: string;
  isContactPersonRequired: boolean;
  isGatedProperty: boolean;
  isGpsAccurate: boolean;
  name: string;
  phoneNumber: string;
  tankConnectionDetails: string;
  tankDistance: string;
  zipCode: string;
}

export interface IDeliveryTime {
  startTime: string;
  driverId: string;
}

export type IAvailableDeliveryTimes = IDeliveryTime[];

export const DeliveryLocationModel = {
  /**
   * @throws {Error}
   */
  get: async (): Promise<IDeliveryLocationModel | null> => {
    try {
      const response = await api.get<IDeliveryLocationModel>(
        DELIVERY_LOCATION_BASE_PATH
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  /**
   * @throws {Error}
   */
  getAll: async (): Promise<IDeliveryLocationModel | null> => {
    try {
      // TODO: typing
      const response = await api.get<any>(`admin/userLocations`);
      return response.data;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  /**
   * @throws {Error}
   */
  put: async (
    deliveryLocation: IDeliveryLocationModel
  ): Promise<IDeliveryLocationModel> => {
    try {
      // TODO: typing
      const response = await api.put<IDeliveryLocationModel>(
        `admin/userLocations/${deliveryLocation._id}`,
        deliveryLocation
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
};
