import React from "react";
import { Grid, Header, Image, Segment } from "semantic-ui-react";

function AboutSection() {
  return (
    <>
      <Segment style={{ padding: "8em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                About Us
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Lunde Water, LLC is a small family business which was
                established in 2003 by Everard and Dave Lunde. Eventually, in
                2016, 3rd generation born Dylan Lunde joined the team.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                Lunde Water delivers bulk amounts of potable drinking water by
                way of tanker truck to the residents of Acton/Agua Dulce &
                surrounding areas. Lunde Water strives to provide peace of mind
                in a timely and professional manner.
              </p>
              <p style={{ fontSize: "1.33em" }}>
                The operation is still run by the same Lunde Family, and as of
                2021 now includes Susie Lunde as a manager. Unfortunately,
                Everard Lunde passed away in 2016, so Dave, Dylan & Susie work
                tirelessly to maintain his legacy and honor his creation.
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              <Image
                bordered
                rounded
                size="large"
                src="/images/lunde-water-team.jpg"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}

export default AboutSection;
