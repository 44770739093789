import React, {useEffect, useCallback, useState} from "react";
import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";

import {useAdminOrdersContext} from "../AdminOrdersContext";
import {AdminModel} from "../../../models/AdminModel";
import {AdminOrdersTableRow} from "./AdminOrdersTableRow";

export const AdminOrdersTable: React.FC = () => {
  const [orders, setOrders] = useState<any>([]);
  const {isSendingInvoice, setIsSendingInvoice} = useAdminOrdersContext();
  const [search, setSearch] = useState<string>("");

  const searchOrders = useCallback((order: any) => {
    const email = order.userId.email.toLowerCase();
    const name = order.deliveryLocationId.name.toLowerCase();
    const address = order.deliveryLocationId.address.toLowerCase();
    if (email.includes(search.toLowerCase())
    || name.includes(search.toLowerCase())
    || address.includes(search.toLowerCase())
    ) {
      return true
    }
  }, [search])

  const reloadOrders = useCallback(() => {
    AdminModel.getAllOrders()
      .then(data => {
        const filteredOrders = data.filter((order: any) => order.userId && order.deliveryLocationId);
        setOrders(filteredOrders);
        setIsSendingInvoice(false);
      })
  }, [setIsSendingInvoice]);

  useEffect(() => {
    reloadOrders()
  }, [reloadOrders, isSendingInvoice]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="search-orders"
          label="Search Orders"
          type="search"
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="orders table">
          <TableHead>
            <TableRow>
              <TableCell>Date ordered</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Details</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.filter(searchOrders).map((order: any, index: number) => (
              <AdminOrdersTableRow order={order} key={index} reloadOrders={reloadOrders}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
