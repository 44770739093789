import React from "react";
import { Container, Typography } from "@material-ui/core";

import { AdminOrdersProvider } from "./AdminOrdersContext";
import { AdminOrdersTable } from "./AdminOrdersTable";

export const AdminOrdersView: React.FC = () => {
  return (
    <AdminOrdersProvider>
      <Container
        style={{
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
        }}
      >
        <Container maxWidth="md" style={{ padding: "2rem 0" }}>
          <Typography
            variant="h3"
            style={{ fontSize: "1.4rem", marginBottom: "1rem" }}
          >
            Customer Orders
          </Typography>
          <AdminOrdersTable />
        </Container>
      </Container>
    </AdminOrdersProvider>
  );
};
