import React, { useState } from "react";
import moment from "moment";
import { Button, ButtonGroup, Dialog, TableCell, TableRow } from "@material-ui/core";

import { CreateInvoiceModal } from "../CreateInvoiceModal";
import { OrderDetailsModal } from "../OrderDetailsModal";
import { IAdminOrder } from "../types";
import { OrderModel } from "../../../models/OrderModel";
import { ConfirmDeleteOrderModal } from "../ConfirmDeleteOrderModal";

interface IAdminOrdersTableRowProps {
  order: IAdminOrder;
  reloadOrders: () => void;
}

export const AdminOrdersTableRow: React.FC<IAdminOrdersTableRowProps> = ({
  order,
  reloadOrders,
}) => {
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [isOrderDeleteConfirmationOpen, setIsOrderDeleteConfirmationOpen] = React.useState(false);

  const handleCreateInvoiceClick = () => {
    setIsCreateInvoiceModalOpen(true);
  };

  const handleCloseInvoiceModal = () => {
    setIsCreateInvoiceModalOpen(false);
  };

  const handleOrderDetailsClick = () => {
    setIsOrderDetailsModalOpen(true);
  };

  const handleCloseOrderDetailsModal = () => {
    setIsOrderDetailsModalOpen(false);
  };

  const handleOrderDeleteConfirmationClick = () => {
    setIsOrderDeleteConfirmationOpen(true);
  };

  const handleOrderDeleteConfirmationClose = () => {
    setIsOrderDeleteConfirmationOpen(false);
  };

  const {
    stripeInvoiceId,
    createdAt,
    userId: { email },
    deliveryLocationId: { name, deliveryCost },
  } = order;

  const deleteOrder = async () => {
    handleOrderDeleteConfirmationClose();
    await OrderModel.delete(order._id);
    reloadOrders();
  }

  return (
    <>
      <CreateInvoiceModal
        order={order}
        isOpen={isCreateInvoiceModalOpen}
        onClose={handleCloseInvoiceModal}
      />
      <OrderDetailsModal
        order={order}
        isOpen={isOrderDetailsModalOpen}
        onClose={handleCloseOrderDetailsModal}
      />
      <ConfirmDeleteOrderModal
        isOpen={isOrderDeleteConfirmationOpen}
        onCancel={handleOrderDeleteConfirmationClose}
        onClose={handleOrderDeleteConfirmationClose}
        onConfirm={deleteOrder}
      />

      <TableRow>
        <TableCell>{moment(createdAt).format("ddd, MMMM Do, YYYY")}</TableCell>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="center">{email}</TableCell>
        <TableCell align="center">
          {
            <Button color="secondary" onClick={handleOrderDetailsClick}>
              Delivery Details
            </Button>
          }
        </TableCell>
        <TableCell align="center">
          {deliveryCost ? `$${deliveryCost}` : null}
        </TableCell>
        <TableCell align="right">
          {stripeInvoiceId ? (
            <Button disabled variant="contained">
              Sent
            </Button>
          ) : (
            <ButtonGroup
              variant="contained"
            >
              <Button onClick={handleCreateInvoiceClick}>
                Create Invoice
              </Button>
              <Button
                onClick={handleOrderDeleteConfirmationClick}
                style={{ color: "white", backgroundColor: "#d32f2f", }}
              >
                Delete Order
              </Button>
            </ButtonGroup>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
