import React from "react";
import { Container, Typography } from "@material-ui/core";

import { AdminDeliveriesTable } from "./AdminDeliveriesTable";

export const AdminDeliveriesView: React.FC = () => {
  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md" style={{ padding: "2rem 0" }}>
        <Typography
          variant="h3"
          style={{ fontSize: "1.4rem", marginBottom: "1rem" }}
        >
          Customers
        </Typography>
        <AdminDeliveriesTable />
      </Container>
    </Container>
  );
};
