import React from "react";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { styled } from "@stitches/react";
import {
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
} from "@material-ui/core";
import { TextField, RadioGroup } from "formik-material-ui";

import { IDeliveryDetailsFields } from "../types";
import { useNewOrderContext } from "../NewOrderContext";

interface DeliveryDetailsFormProps {
  onSubmit: (formData: IDeliveryDetailsFields) => void;
  onCancel: () => void;
}

export const DeliveryDetailsForm: React.FC<DeliveryDetailsFormProps> = ({
  onSubmit,
  onCancel,
}) => {
  const {
    formData: {
      isContactPersonRequired,
      isGpsAccurate,
      gpsInaccurateDescription,
      isGatedProperty,
      gatedPropertyDetails,
      tankConnectionDetails,
      tankDistance,
    },
  } = useNewOrderContext();

  const getResetValues = (): IDeliveryDetailsFields => ({
    isContactPersonRequired: isContactPersonRequired || "",
    isGpsAccurate: isGpsAccurate || "",
    gpsInaccurateDescription: gpsInaccurateDescription || "",
    isGatedProperty: isGatedProperty || "",
    gatedPropertyDetails: gatedPropertyDetails || "",
    tankConnectionDetails: tankConnectionDetails || "",
    tankDistance: tankDistance || "",
  });

  const handleSubmit = (values: IDeliveryDetailsFields) => {
    onSubmit(values);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={getResetValues()}
      validationSchema={object({
        isContactPersonRequired: string().required("Required"),
        isGpsAccurate: string().required("Required"),
        gpsInaccurateDescription: string().when("isGpsAccurate", {
          is: (isGpsAccurate: string) => isGpsAccurate === "false",
          then: string().required("Required"),
        }),
        isGatedProperty: string().required("Required"),
        gatedPropertyDetails: string().when("isGatedProperty", {
          is: (isGatedProperty: string) => isGatedProperty === "true",
          then: string().required("Required"),
        }),
        tankConnectionDetails: string().required("Required"),
        tankDistance: string().required("Required"),
      })}
    >
      {({ values, errors, touched, setValues }) => (
        <StyledForm>
          <StyledFieldWrapper>
            <FormLabel>
              Does someone need to be present at the time of service?
            </FormLabel>
            <Field component={RadioGroup} name="isContactPersonRequired">
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </Field>
            {errors.isContactPersonRequired &&
              touched.isContactPersonRequired && (
                <FormHelperText error>
                  {errors.isContactPersonRequired}
                </FormHelperText>
              )}
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <FormLabel>
              Does GPS take us directly to the service location?
            </FormLabel>
            <Field component={RadioGroup} name="isGpsAccurate">
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </Field>
            {errors.isGpsAccurate && touched.isGpsAccurate && (
              <FormHelperText error>{errors.isGpsAccurate}</FormHelperText>
            )}
            {values.isGpsAccurate === "false" && (
              <Field
                name="gpsInaccurateDescription"
                component={TextField}
                fullWidth
                multiline
                rows={3}
                disabled={false}
                label="Please provide GPS coordinates and/or directions."
              />
            )}
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <FormLabel>Do we need to enter a gated property?</FormLabel>
            <Field component={RadioGroup} name="isGatedProperty">
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </Field>
            {errors.isGatedProperty && touched.isGatedProperty && (
              <FormHelperText error>{errors.isGatedProperty}</FormHelperText>
            )}
            {values.isGatedProperty === "true" && (
              <Field
                name="gatedPropertyDetails"
                component={TextField}
                fullWidth
                multiline
                rows={3}
                disabled={false}
                label="Please provide any info we'll need to enter the property. i.e. gate codes, padlock combos, etc..."
              />
            )}
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Field
              name="tankConnectionDetails"
              component={TextField}
              fullWidth
              multiline
              rows={3}
              disabled={false}
              label="How do we connect to the tank? i.e. hydrant, valve, top hole..."
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Field
              name="tankDistance"
              component={TextField}
              fullWidth
              disabled={false}
              label="How close can we get to the fill spot (hydrant, tank, pool, etc.)?"
            />
          </StyledFieldWrapper>

          <StyledButtonWrapper>
            <Button
              style={{ marginRight: "1rem" }}
              variant="outlined"
              color="primary"
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={() => {
                setValues(getResetValues());
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </StyledButtonWrapper>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledButtonWrapper = styled("div", {
  marginTop: "2rem",
});

const StyledFieldWrapper = styled("div", {
  marginBottom: "1rem",
});

const StyledForm = styled(Form, {
  width: "100%",
});
