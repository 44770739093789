import React, {useState, useEffect} from "react";
import {Button} from "../button/Button";
import {Link} from "react-router-dom";
import "./Navbar.css";
import {MdInvertColors} from "react-icons/md";
import {FaBars, FaTimes} from "react-icons/fa";

import {useUserContext} from "../../contexts/UserContext";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const {logout, isAuthenticated, isAdmin} = useUserContext();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  const AuthLinks = () => (
    <>
      {!isAdmin ? (
        <>
          <li className="nav-item">
            <Link
              to="/neworder"
              className="nav-links order"
              onClick={closeMobileMenu}
            >
              Create Order
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/orders"
              className="nav-links order"
              onClick={closeMobileMenu}
            >
              Order History
            </Link>
          </li>
        </>
      ) : null}
      <li className="nav-item">
        <Link to="/" className="nav-links" onClick={() => {closeMobileMenu(); logout()}}>
          <span className="hide-sm">Logout</span>
        </Link>
      </li>
    </>
  );

  const GuestLinks = () => (
    <>
      <li className="nav-item">
        <Link className="nav-links login" to="/login" onClick={closeMobileMenu}>
          Login
        </Link>
      </li>

      <li className="nav-btn">
        {button ? (
          <Link to="/register" className="nav-links" onClick={closeMobileMenu}>
            <Button buttonStyle="btn--outline">SIGN UP</Button>
          </Link>
        ) : (
          <Link to="/register" className="nav-links" onClick={closeMobileMenu}>
            <Button
              buttonStyle="btn--outline"
              buttonSize="btn--mobile"
              onClick={closeMobileMenu}
            >
              SIGN UP
            </Button>
          </Link>
        )}
      </li>
    </>
  );

  const AdminLinks = () => (
    <>
      <li className="nav-item">
        <Link className="nav-links" to="/admin/orders" onClick={closeMobileMenu}>
          Edit Orders
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-links" to="/admin/deliveries" onClick={closeMobileMenu}>
          Edit Deliveries
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-links" to="/admin/users" onClick={closeMobileMenu}>
          Edit Users
        </Link>
      </li>
    </>
  );

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <MdInvertColors className="navbar-icon" />
            LUNDE WATER, LLC
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links home" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about-us" className="nav-links home" onClick={closeMobileMenu}>
                About
              </Link>
            </li>

            {isAdmin && <AdminLinks />}
            {isAuthenticated ? <AuthLinks /> : <GuestLinks />}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
