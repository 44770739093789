import React, {useState} from "react";
import {styled} from "@stitches/react";
import {Button, CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router";
import {Alert} from "@material-ui/lab";

import {PHONE_NUMBER} from "../../constants/company";

import {ContactDetails} from "./ContactDetails";
import {DeliveryDetails} from "./DeliveryDetails";
import {DeliveryTime} from "./DeliveryTime";
import {useNewOrderContext} from "./NewOrderContext";

export const NewOrderForm: React.FC<any> = () => {
  const {
    isDeliveryDetailsValid,
    isContactDetailsValid,
    isDeliveryTimeValid,
    isLoadingUserData,
    submitNewOrder,
  } = useNewOrderContext();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await submitNewOrder();
      history.push("/orders?success=true");
    } catch (e: any) {
      console.error(e);
      setHasServerError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoadingUserData) {
    return (
      <StyledCircularProgressContainer>
        <CircularProgress />
      </StyledCircularProgressContainer>
    );
  }

  return (
    <>
      <ContactDetails />
      <DeliveryDetails />
      <DeliveryTime />

      <StyledSubmitButtonWrapper>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          disabled={
            !isDeliveryDetailsValid ||
            !isContactDetailsValid ||
            !isDeliveryTimeValid ||
            isSubmitting
          }
        >
          Submit
        </Button>

        {hasServerError && (
          <StyledServerAlert severity="error">
            Uht oh! Our computers screwed up again. Please try again
            later or call us at {PHONE_NUMBER}.
          </StyledServerAlert>
        )}
      </StyledSubmitButtonWrapper>
    </>
  );
};

const StyledSubmitButtonWrapper = styled("div", {
  marginTop: "2rem",
});

const StyledServerAlert = styled(Alert, {
  marginTop: "2rem",
});

const StyledCircularProgressContainer = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
});
