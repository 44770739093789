/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

import ResponsiveContainer from "../ResponsiveLayout/ResponsiveContainer";
import HomepageHeading from "../HomepageHeading/HomepageHeading";
import HeroSection1 from "../../hero/HeroSection1";
import BottomHeroSections from "../../../features/herosections/BottomHeroSections";

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */

// const panes = [
//   {
//     menuItem: "Need to See This",
//     render: () => (
//       <Tab.Pane>
//         <Table singleLine>
//           <Table.Header>
//             <Table.Row>
//               <Table.HeaderCell>Name</Table.HeaderCell>
//               <Table.HeaderCell>Registration Date</Table.HeaderCell>
//               <Table.HeaderCell>E-mail address</Table.HeaderCell>
//               <Table.HeaderCell>Premium Plan</Table.HeaderCell>
//             </Table.Row>
//           </Table.Header>

//           <Table.Body>
//             <Table.Row>
//               <Table.Cell>John Lilki</Table.Cell>
//               <Table.Cell>September 14, 2013</Table.Cell>
//               <Table.Cell>jhlilk22@yahoo.com</Table.Cell>
//               <Table.Cell>No</Table.Cell>
//             </Table.Row>
//             <Table.Row>
//               <Table.Cell>Jamie Harington</Table.Cell>
//               <Table.Cell>January 11, 2014</Table.Cell>
//               <Table.Cell>jamieharingonton@yahoo.com</Table.Cell>
//               <Table.Cell>Yes</Table.Cell>
//             </Table.Row>
//             <Table.Row>
//               <Table.Cell>Jill Lewis</Table.Cell>
//               <Table.Cell>May 11, 2014</Table.Cell>
//               <Table.Cell>jilsewris22@yahoo.com</Table.Cell>
//               <Table.Cell>Yes</Table.Cell>
//             </Table.Row>
//           </Table.Body>
//         </Table>
//       </Tab.Pane>
//     ),
//   },
//   {
//     menuItem: "FAQs",
//     render: () => (
//       <Tab.Pane>
//         <AccordionExampleStyled />
//       </Tab.Pane>
//     ),
//   },
//   { menuItem: "Tab 3", render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
// ];

const Homepage = () => {

  return (
    <ResponsiveContainer>
      <Visibility
        once={false}
      >
        <Segment
          inverted={true}
          textAlign="center"
          style={{
            backgroundSize: "cover",
            backgroundImage: "url('images/wave.svg')",
            // backgroundColor: "#1b1c1d",
            // minHeight: 700,
            padding: "6rem 0rem",
          }}
          vertical
        >
          <HomepageHeading mobile={false} />
        </Segment>
      </Visibility>

      <HeroSection1 />
      <BottomHeroSections />
    </ResponsiveContainer>
  );
};

export default Homepage;
