import React, { useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { useUserContext } from "../../../contexts/UserContext";

interface FieldTypes {
  email: string;
  password: string;
}

const ForgotPasswordForm: React.FC<any> = () => {
  const { forgotPassword } = useUserContext();
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);

  const onSubmit = ({ email }: FieldTypes) => {
    forgotPassword(email);
    setEmailSuccess(true);
  };

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm<FieldTypes>();

  return (
    <Form onSubmit={handleSubmit(onSubmit)} success={emailSuccess}>
      <Form.Input
        fluid
        label="Email Address"
        placeholder="Email Address"
        name="email"
        onChange={async (e) => {
          setValue("email", e.target.value);
        }}
        onBlur={(e: any) =>
          setValue("email", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.email
            ? {
                content: "Please enter your email address",
                pointing: "above",
              }
            : undefined
        }
      />
      <Button type="submit" disabled={!isValid || isSubmitting}>
        Submit
      </Button>
      <Message
        success
        header="Email Submitted"
        content="If there are any accounts associated with this email address, you'll revieve an email shortly. "
      />
    </Form>
  );
};

export default ForgotPasswordForm;
