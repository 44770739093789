import React from "react";
import { RouteProps, Redirect } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";
import PrivateRoute from "./PrivateRoute";

export const AdminRoute: React.FC<RouteProps> = ({
  component: Component,
  children,
  ...rest
}: any) => {
  const { isAdmin, isLoadingUser } = useUserContext();

  if (!isLoadingUser && !isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <PrivateRoute {...rest} component={Component}>
      {children}
    </PrivateRoute>
  );
};
