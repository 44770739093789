import { useRef } from "react";
import { useHistory } from "react-router";
import { styled } from "@stitches/react";

const UnauthenticatedModal = () => {
  const history = useHistory();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  return (
    <Root>
      <Dialog role="dialog" ref={ref}>
        <h1>Session Expired</h1>
        <p>You must be logged in to visit that page</p>
        <Button onClick={() => history.push("/login")}>Ok</Button>
      </Dialog>
    </Root>
  );
};

export default UnauthenticatedModal;

const Root = styled("div", {
  width: "100%",
  backgroundColor: "white",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1040",
});

const Dialog = styled("div", {
  height: "40vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  zIndex: "1050",
  flexBasis: "48rem",
});

const Button = styled("button", {
  marginTop: "1rem",
  height: "3rem",
  width: "12rem",
  fontSize: "1.2rem",
  backgroundColor: "#1c2237",
  border: "none",
  borderRadius: "2px",
  color: "white",
  "&:hover": {
    cursor: "pointer",
  },
});
