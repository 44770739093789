import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Fade,
} from "@material-ui/core";
import { useLocation } from "react-router";
import { Alert, AlertTitle } from "@material-ui/lab";

import { OrderModel, IOrderWithLocation } from "../../models/OrderModel";

export const OrdersView: React.FC = () => {
  const [orders, setOrders] = useState<IOrderWithLocation[]>([]);
  const location = useLocation();
  const showSuccessMessage = location.search.includes("success");

  useEffect(() => {
    (async () => {
      const data = await OrderModel.getAll();
      setOrders(data);
    })();
  }, []);

  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md" style={{ marginTop: "3rem", padding: "2rem 0" }}>
        {showSuccessMessage && (
          <Fade in={showSuccessMessage}>
            <Alert severity="success">
              <AlertTitle>Order created!</AlertTitle>
              Thank you for scheduling a delivery. We will reach out if we need
              more information.
            </Alert>
          </Fade>
        )}

        <Typography
          variant="h3"
          style={{ fontSize: "2rem", margin: "1.5rem 0" }}
        >
          Orders
        </Typography>

        <TableContainer component={Paper}>
          <Table aria-label="orders table">
            <TableHead>
              <TableRow>
                <TableCell>Date ordered</TableCell>
                <TableCell align="center">Delivery date</TableCell>
                <TableCell align="right">Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order: any, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {moment(order.createdAt).format("ddd, MMMM Do, YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {moment(order.deliveryAt).format("ddd, MMMM Do, YYYY")}
                  </TableCell>
                  <TableCell align="right">{order.address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Container>
  );
};
