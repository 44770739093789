import React from "react";
import HeroSection from "../../components/hero/HeroSection";
import { homeObjZero, homeObjTwo, homeObjFive } from "./Data";
// import CardExampleCard from '../../CardExampleCard/CardExampleCard';

function BottomHomeHeroSections() {
  return (
    <>
      <HeroSection {...homeObjZero} />
      {/* <HeroSection {...homeObjOne} /> */}
      <HeroSection {...homeObjTwo} />
      {/* <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFour} /> */}
      <HeroSection {...homeObjFive} />
      {/* <Pricing /> */}
    </>
  );
}

export default BottomHomeHeroSections;
