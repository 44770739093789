import React, {useEffect, useState} from "react";
import {Form, Button, Message} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";

import {AdminModel} from "../../../models/AdminModel";
import {PHONE_NUMBER} from "../../../constants/company";
import {validateEmail, validatePassword} from "../../../utils/validation";

interface FieldTypes {
  email: string;
  password: string;
  confirmPassword: string;
  isAdmin: boolean;
}

export const NewUserForm: React.FC<any> = () => {
  const history = useHistory();
  const [hasServerError, setServerError] = useState<boolean>(false);
  const [serverErrorMessage, setServerErrorMessage] = React.useState<string>()
  const {
    register,
    handleSubmit,
    formState: {errors, isValid, isSubmitting},
    watch,
    setValue,
    trigger,
  } = useForm<FieldTypes>();

  const watchIsAdmin = watch("isAdmin", false);

  const onSubmit = async ({email, password, isAdmin}: FieldTypes) => {
    const response = await AdminModel.newUser({email, password, isAdmin});
    if (response.status === "success") {
      history.push("/admin/users");
    } else {
      setServerError(true);
      setServerErrorMessage(response.message);
    }
  };

  useEffect(() => {
    register("email", {
      validate: (value) => validateEmail(value),
    });
    register("password", {
      validate: (value) => validatePassword(value),
    });
    register("confirmPassword", {
      validate: (value) => value === watch("password"),
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} error={hasServerError}>
      <Form.Input
        fluid
        label="Email Address"
        placeholder="Email Address"
        name="email"
        onChange={async (e) => {
          setValue("email", e.target.value);
          // only update validation on change if the user corrected their email address
          if (validateEmail(e.target.value)) {
            await trigger("email");
          }
        }}
        onBlur={(e: any) =>
          setValue("email", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.email
            ? {
              content: "Please enter a valid email address",
              pointing: "above",
            }
            : undefined
        }
      />
      <Form.Input
        fluid
        label="Password"
        placeholder="Enter a password"
        type="password"
        name="password"
        onChange={async (e) => {
          setValue("password", e.target.value);
          // only update validation on change if the user corrected their email address
          if (validatePassword(e.target.value)) {
            await trigger("password");
          }
        }}
        onBlur={(e: any) =>
          setValue("password", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.password
            ? {
              content: "Password must be 6 characters long",
              pointing: "above",
            }
            : undefined
        }
      />
      <Form.Input
        fluid
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Confirm your password"
        type="password"
        onChange={async (e) => {
          setValue("confirmPassword", e.target.value);
          // only update validation on change if the user corrected their password
          if (e.target.value === watch("password")) {
            await trigger("confirmPassword");
          }
        }}
        onBlur={(e: any) =>
          setValue("confirmPassword", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.confirmPassword
            ? {
              content: "Passwords must match",
              pointing: "above",
            }
            : undefined
        }
      />
      <Form.Checkbox
        fluid
        name="isAdmin"
        label="Is this user an admin?"
        checked={watchIsAdmin}
        value={watchIsAdmin ? "admin" : "user"}
        onChange={() => setValue("isAdmin", !watchIsAdmin, {shouldDirty: true})}
      />
      <Button type="submit" disabled={!isValid || isSubmitting}>
        Submit
      </Button>
      <Message
        error
        header="Server Error"
        content={serverErrorMessage || `Uht oh! Our computers screwed up again. Please try again later or call us at ${PHONE_NUMBER}.`}
      />
    </Form>
  );
};

