import React, { useState } from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";

import { EditUserLocationModal } from "../EditUserLocationModal";
import { IAdminLocation } from "../types";

interface IAdminDeliveriesTableRowProps {
  location: IAdminLocation;
}

export const AdminDeliveriesTableRow: React.FC<IAdminDeliveriesTableRowProps> = ({
  location,
}) => {
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);

  const handleEditClick = () => {
    setIsCreateInvoiceModalOpen(true);
  };

  const handleClose = () => {
    setIsCreateInvoiceModalOpen(false);
  };

  const {
    deliveryCost,
    address,
    estimatedMinutes,
    user: { email },
  } = location;

  return (
    <>
      <EditUserLocationModal
        location={location}
        isOpen={isCreateInvoiceModalOpen}
        onClose={handleClose}
      />

      <TableRow>
        <TableCell>{email}</TableCell>
        <TableCell align="center">{address}</TableCell>
        <TableCell align="center">{deliveryCost}</TableCell>
        <TableCell align="center">{estimatedMinutes}</TableCell>
        <TableCell align="right">
          <Button onClick={handleEditClick} color="primary" variant="contained">
            Edit
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
