import React from "react"
import { Container, Header } from "semantic-ui-react";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import CustomerCalculatorForm from "./CustomerCalculatorForm";
import CustomerEstimateDisplay from "./CustomerEstimateDisplay";
import { CustomerEstimateProvider } from "./CustomerEstimateContext"

const CustomerCalculatorView: React.FC = () => {
    return (
      <CustomerEstimateProvider>
        <Container
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0",
          }}
        >
          <Container text style={{ padding: "0" }}>
            <Header as="h1">Please Submit Address Below for Estimate</Header>
            
              <CustomerCalculatorForm />
              <CustomerEstimateDisplay/>
            
            <Container
              text
              style={{ display: "flex", padding: "0px", marginTop: "2rem" }}
            >
              <Typography style={{ flexBasis: "50%" }}>
                Don't have an address or account yet? <Link to="/register">Sign Up</Link>
              </Typography>
            </Container>
          </Container>
        </Container>
      </CustomerEstimateProvider>
    );
  };
  
  export default CustomerCalculatorView;
  