import React, {useEffect, useState} from "react";
import {Form, Button, Message} from "semantic-ui-react";
import {useForm} from "react-hook-form";

import {PHONE_NUMBER} from "../../../constants/company";
import {validateEmail, validatePassword} from "../../../utils/validation";
import {AdminModel} from "../../../models/AdminModel";

interface FieldTypes {
  email: string;
  password: string;
  confirmPassword: string;
  isAdmin: boolean;
}

export const EditUserForm: React.FC<any> = ({user, onClose}) => {
  const [hasServerError, setServerError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: {errors, isValid, isSubmitting},
    watch,
    setValue,
    trigger,
  } = useForm<FieldTypes>({
    defaultValues: {
      email: user.email,
      isAdmin: user.role === "admin",
    }
  });

  const watchIsAdmin = watch("isAdmin", user.role === "admin");

  const onSubmit = async ({email, password, isAdmin}: FieldTypes) => {
    const updatedUser = await AdminModel.putUser({_id: user._id, email, password, isAdmin});
    if (updatedUser) {
      onClose();
    } else {
      setServerError(true);
    }
  };

  const deleteUser = async () => {
    await AdminModel.deleteUser(user._id);
    onClose();
  }

  useEffect(() => {
    register("email", {
      validate: (value) => validateEmail(value),
    });
    register("password", {
      validate: (value) => {
        // If admin doesn't want to change the user password
        if (!watch("confirmPassword") && !value) {
          return true
        } else {
          return value && validatePassword(value)
        }
      },
    });
    register("confirmPassword", {
      validate: (value) => {
        return value === watch("password")
      },
    });
    trigger(["email", "password", "confirmPassword", "isAdmin"]);
  }, []);

  return (
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)} error={hasServerError}>
      <Form.Input
        fluid
        label="Email Address"
        placeholder="Email Address"
        defaultValue={user.email}
        name="email"
        onChange={async (e: any) => {
          setValue("email", e.target.value);
          // only update validation on change if the user corrected their email address
          if (validateEmail(e.target.value)) {
            await trigger("email");
          }
        }}
        onBlur={(e: any) =>
          setValue("email", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.email
            ? {
              content: "Please enter a valid email address",
              pointing: "above",
            }
            : undefined
        }
      />
      <Form.Input
        fluid
        autoComplete="new-password"
        label="Password"
        placeholder="Enter a password"
        type="password"
        name="password"
        onChange={async (e: any) => {
          setValue("password", e.target.value);
          // only update validation on change if the user corrected their password
          if (validatePassword(e.target.value) || !e.target.value) {
            await trigger("password");
          }
        }}
        onBlur={(e: any) =>
          setValue("password", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.password
            ? {
              content: "Password must be 6 characters long",
              pointing: "above",
            }
            : undefined
        }
      />
      <Form.Input
        fluid
        autoComplete="new-password"
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Confirm your password"
        type="password"
        onChange={async (e: any) => {
          setValue("confirmPassword", e.target.value);
          // only update validation on change if the user corrected their password
          if (e.target.value === watch("password") || !e.target.value) {
            await trigger("confirmPassword");
          }
        }}
        onBlur={(e: any) =>
          setValue("confirmPassword", e.target.value, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }
        error={
          errors.confirmPassword
            ? {
              content: "Passwords must match",
              pointing: "above",
            }
            : undefined
        }
      />
      <Form.Checkbox
        fluid
        name="isAdmin"
        label="User is an admin"
        defaultChecked={watchIsAdmin}
        checked={watchIsAdmin}
        value={watchIsAdmin ? "admin" : "user"}
        onChange={() => setValue("isAdmin", !watchIsAdmin, {shouldDirty: true})}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button type="submit" disabled={!isValid || isSubmitting}>
          Submit
        </Button>
        <Button
          type="button"
          color="red"
          onClick={deleteUser}
        >
          Delete User
        </Button>
      </div>
      <Message
        error
        header="Server Error"
        content={`Uht oh! Our computers screwed up again. Please try again later or call us at ${PHONE_NUMBER}.`}
      />
    </Form>
  );
};
