import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails } from "@material-ui/core";
import { styled } from "@stitches/react";

import { AccordionSummary } from "../AccordionSummary";
import { useNewOrderContext } from "../NewOrderContext";
import { IDeliveryDetailsFields } from "../types";

import { DeliveryDetailsForm } from "./DeliveryDetailsForm";

export const DeliveryDetails: React.FC<any> = () => {
  const {
    setDeliveryDetails,
    isDeliveryDetailsValid,
    isContactDetailsValid,
  } = useNewOrderContext();
  const [isOpen, setIsOpen] = useState(false);

  const isComplete = isDeliveryDetailsValid;

  const handleSubmit = (values: IDeliveryDetailsFields) => {
    setDeliveryDetails(values);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  /**
   * Automatically open if contact details was just finished and this is unfinished
   */
  useEffect(() => {
    if (isContactDetailsValid && !isDeliveryDetailsValid) {
      setIsOpen(true);
    }
  }, [isContactDetailsValid, isDeliveryDetailsValid]);

  return (
    <Accordion expanded={isOpen}>
      <AccordionSummary
        expanded={isOpen}
        onClick={() => setIsOpen(true)}
        title="Delivery Details"
        description={
          isComplete
            ? "Your delivery details are complete!"
            : "Please fill out delivery details before submitting."
        }
        isComplete={isComplete}
      />
      <StyledContent>
        <DeliveryDetailsForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </StyledContent>
    </Accordion>
  );
};

const StyledContent = styled(AccordionDetails, {
  paddingLeft: "2rem !important",
});
