import React from "react";
import { Link } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import { Typography } from "@material-ui/core";

import LoginForm from "./LoginForm/LoginForm"; // make dynamic?

const LoginView: React.FC = () => {
  return (
    <Container
      style={{
        minHeight: "75vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
      }}
    >
      <Container text style={{ padding: "0" }}>
        <Header as="h1">Log In</Header>
        <LoginForm />
        <Container
          text
          style={{ display: "flex", padding: "0px", marginTop: "2rem" }}
        >
          <Typography style={{ flexBasis: "50%" }}>
            Don't have an account? <Link to="/register">Sign Up</Link>
          </Typography>
          <Typography>
            Forgot your password?{"  "}
            <Link to="/forgotpassword">Forgot Password</Link>
          </Typography>
        </Container>
      </Container>
    </Container>
  );
};

export default LoginView;
