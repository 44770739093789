import api from "../utils/api";
import { ISmartWaterLocation } from "../views/SmartWaterView/types";

const SMART_WATER_BASE_PATH = "/smartWater";

export interface ISmartWaterUser {
  // smart water user details
  tankLevel: number;
  daysToEmpty: number;
  dailyWaterUse: number;
  smartWaterID: string;
  tankName: string;
  tankID: number;
};

export const SmartWaterModel = {
  /**
   * @param orderProp {ISmartWaterUser}
   * @throws {Error}
   */
   create: async (smartWaterArgs: ISmartWaterUser): Promise<ISmartWaterLocation> => {
    try {
      const response = await api.post<ISmartWaterUser>(SMART_WATER_BASE_PATH, smartWaterArgs);
      return response.data;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  /**
   * @param orderProp {ISmartWaterUser}
   * @throws {Error}
   */
  getAll: async (): Promise<ISmartWaterUser[]> => {
    try {
      const response = await api.get<ISmartWaterUser[]>(
        `${SMART_WATER_BASE_PATH}`
      );
      return response.data || [];
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
};
