import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {IUser} from "../../types";
import api from "../../utils/api";
import {getAuthToken, setAuthToken} from "../../utils/auth";

type ApiResponse<A> = {
  status: "success";
  data: A;
} | {
  status: "failed";
  message?: string;
};

enum UserRole {
  ADMIN = "admin",
  USER = "user",
}
interface LoginArgsType {
  email: string;
  password: string;
}
interface ResetPasswordArgs {
  email: string;
  token: string;
  password: string;
}

interface UserContextType {
  isLoadingUser: boolean;
  user: IUser | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  logout: () => void;
  login: (user: LoginArgsType) => Promise<IUser | null>;
  signUp: (user: LoginArgsType) => Promise<ApiResponse<IUser>>;
  forgotPassword: (email: string) => void;
  resetPassword: (args: ResetPasswordArgs) => Promise<void>;
}

export const UserContext = createContext<Partial<UserContextType>>({});

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context as UserContextType;
};

export const UserProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const logout = () => {
    setUser(null);
    setIsAdmin(false);
    setIsAuthenticated(false);
    setAuthToken(null);
  };

  const login = async (user: LoginArgsType): Promise<IUser | null> => {
    try {
      const res = await api.post<IUser>("/users/login", user);

      const loggedInUser = {...res.data};

      setIsAuthenticated(true);
      setUser(loggedInUser);
      setIsAdmin(loggedInUser.role === UserRole.ADMIN);

      return loggedInUser;
    } catch (err) {
      logout();
      console.error(err);
      return null;
    }
  };

  const signUp = async (user: {
    email: string;
    password: string;
  }): Promise<ApiResponse<IUser>> => {
    try {
      const res = await api.post<IUser>("/users/register", user);
      const newUser = {...res.data};

      setIsAuthenticated(true);
      setUser(newUser);
      setIsAdmin(newUser.role === UserRole.ADMIN);

      return {status: "success", data: newUser};
    } catch (err) {
      logout();
      console.log(err);

      // @ts-ignore
      if (err?.response?.status === 409) {
        return {status: "failed", message: "User already exists"};
      }

      return {status: "failed"};
    }
  };

  const forgotPassword = (email: string) => {
    api.post("/users/forgotpassword", {email});
  };

  /**
   *
   * @param args
   * @throws {Error}
   */
  const resetPassword = async (args: ResetPasswordArgs) => {
    try {
      const res = await api.post("/users/resetpassword", args);
      console.log(res);
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        throw new Error(e.response?.data);
      }
      console.log("catch");
      throw new Error("Something broke");
    }
  };

  useEffect(() => {
    // on app load, try to grab the user with the token stored in localstorage
    if (getAuthToken() !== null) {
      (async () => {
        try {
          const resp = await api.get<any>("/users/authorize");

          const user = resp.data;
          if (user) {
            setUser(user);
            setIsAuthenticated(true);
            setIsAdmin(user?.role === UserRole.ADMIN);
          }
        } catch {
          logout();
        }
        setIsLoadingUser(false);
      })();
    } else {
      setIsLoadingUser(false);
      logout();
    }
  }, []);

  const token = getAuthToken();
  const handleStorageEventListener = useCallback(() => {
    if (!token) {
      logout();
    }
  }, [token]);

  useEffect(() => {
    // if the token is cleared from localstorage for whatever reason, log out the user
    window.addEventListener("storage", handleStorageEventListener);
    return () => {
      // cleanup listener
      window.removeEventListener("storage", handleStorageEventListener);
    };
  }, [handleStorageEventListener]);

  const value: UserContextType = {
    isAuthenticated,
    isLoadingUser,
    logout,
    login,
    signUp,
    forgotPassword,
    resetPassword,
    user,
    isAdmin,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
