import api from "../utils/api";
import {IUser} from "../types";
const ADMIN_BASE_PATH = "/admin";

interface IUpdateUser {
  _id: string;
  email: string;
  password?: string;
  isAdmin: boolean;
}

interface INewUser {
  email: string;
  password: string;
  isAdmin: boolean;
}

type ApiResponse<A> = {
  status: "success";
  data: A;
} | {
  status: "failed";
  message?: string;
};

// TODO: Add typing
export const AdminModel = {
  getAllOrders: async (): Promise<any> => {
    try {
      const response = await api.get<any>(`${ADMIN_BASE_PATH}/orders`);
      return response.data || [];
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  getAllUsers: async (): Promise<IUser[]> => {
    try {
      const response = await api.get<any>(`${ADMIN_BASE_PATH}/users`);
      return response.data || [];
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  putUser: async (
    user: IUpdateUser
  ): Promise<IUser> => {
    try {
      const response = await api.put(
        `${ADMIN_BASE_PATH}/users/${user._id}`,
        user
      );
      return response.data;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  },
  newUser: async (user: INewUser): Promise<ApiResponse<IUser>> => {
    try {
      const response = await api.post<IUser>(
        `${ADMIN_BASE_PATH}/users`,
        user
      );
      return {status: "success", data: response.data};
    } catch (e) {
      console.error(e);

      // @ts-ignore
      if (e?.response?.status === 409) {
        return {status: "failed", message: "User already exists"};
      }
      return {status: "failed"};
    }
  },
  deleteUser: async (id: IUser["id"]): Promise<void> => {
    try {
      await api.delete<void>(`${ADMIN_BASE_PATH}/users/${id}`);
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
