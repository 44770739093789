import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { EditUserForm } from "./EditUserForm";

import { IUser } from "../../../types";

interface IEditUserModalProps {
  user: IUser;
  isOpen: boolean;
  onClose: () => void;
}

export const EditUserModal = ({
  user,
  isOpen,
  onClose,
}: IEditUserModalProps) => {

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      aria-labelledby="form-dialog-title"
    >
      <Button
        onClick={onClose}
        style={{ width: "2rem", alignSelf: "flex-end" }}
      >
        X
      </Button>
      <DialogTitle id="form-dialog-title">
        Edit User
      </DialogTitle>
      <DialogContent>
        <EditUserForm
          user={user}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

