import React from "react";
import { Container, Header } from "semantic-ui-react";

function HomepageHeading({ mobile }) {
  return (
    <Container text>
      <Header
        as="h1"
        content="Lunde Water, LLC"
        inverted
        style={{
          fontSize: mobile ? "2em" : "3em",
          fontWeight: "normal",
          marginBottom: 0,
          // marginTop: mobile ? "1.5em" : "3em",
        }}
      />
      <Header
        as="h2"
        content="Your Premium Water Hauler"
        inverted
        style={{
          fontSize: mobile ? "1.3em" : "1.5em",
          fontWeight: "normal",
          // marginTop: mobile ? "0.5em" : "1.5em",
        }}
      />
    </Container>
  );
}

export default HomepageHeading;
